<template>
  <div class=".visa">
    <div>{{$t('visa.infos.info1')}}</div>
    <ul>
      <li>{{object_lib}}</li>
    </ul>
    <div>
      {{$t('visa.infos.info2')}}
      <a-select style="width: 120px">
        <a-select-option
          v-for="item in actions"
          :key="item.id_action"
          :value="item.id_action"
          @click="select(item)"
        >{{item.lib_action }}</a-select-option>
      </a-select>
      <div style="color:red;" v-if="!visaOk">{{visaMsg}}</div>
    </div>
    <div v-if="visaOk">
      <div style="margin-top:20px" v-if="params!=='' && params.comment">
        <div>{{$t('visa.commentaire')}}</div>
        <a-textarea v-model="commentaire" allow-clear placeholder="$t('visa.comment')" :rows="4" />
      </div>
      <div style="margin-top:20px" v-if="params!=='' && params.advancedComment">
        <div style="color:red; margin-top:20px">{{$t('visa.alertes.advancedComment')}}</div>
      </div>
        <a-button class="validation-visa" @click="valider_visa">{{$t('visa.validate')}} </a-button>
    </div>
    <div style="color:red; margin-top:20px">{{$t('visa.alertes.workflowEtape')}}</div>
  </div>
</template>

<script>
import Workflow from "~/service/Ged/Workflow";
import { mapGetters,mapActions } from 'vuex'

export default {
  name: "Visa",
  props: ["file_id", "mission_id", "object_lib"],
  data() {
    return {
      selected: "",
      params: "",
      visaOk: false,
      visaMsg: "",
      state_id: "",
      commentaire:"",
   };
  },
  asyncComputed: {
    async actions() {
      let actions = await Workflow.get_actions(this.file_id, this.mission_id);
      return actions;
    },
  },
  methods: {
        ...mapActions('arborescence',['updateArbosTodisplay']),
        ...mapActions(['updateFilesAndDirectoryToDisplay']),

    async select(item) {
      this.state_id =item.id_action;
      this.params = item.window_params;
      let visaBlocked = await Workflow.is_visa_blocked(this.file_id, this.mission_id,item.id_action);
      this.visaOk = visaBlocked.success;
      this.visaMsg = visaBlocked.msg;
    },
   async valider_visa() {
       let result=await Workflow.set_visa(
        this.file_id,
        this.mission_id,
        this.state_id,
        this.commentaire
      );
      if(result.success){
        this.$notification['success']({
          message: this.getTranslation('visa_success'),
          description:
            this.getTranslation('visa_success_description'),
          duration: 2,
        });
        this.$emit('exit', true);
        await this.updateArbosTodisplay();
        this.updateFilesAndDirectoryToDisplay({ selectedKeyDirectory: this.selectedKeyDirectory, page: this.currentPage});
      }else{
        this.$notification['error']({
          message: this.getTranslation('visa_error'),
          description:
            this.getTranslation('visa_error_description'),
          duration: 2,
        });
      }
      
    },
  },

  computed: {
    getTranslation() {
      return (key) => this.$t(`visa.${key}`);
    },

        ...mapGetters("directories", 
      { selectedKeyDirectory: "getSelectedKeyDirectory" },
    ),

       ...mapGetters("files", 
      { currentPage: "getCurrentPage" },
    ),
  },
};
</script>